.__grid__ {
  width: 100%;
  //background: grey;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(110px, 2fr));
  column-gap: 1.5rem;

  .item {
    position: relative;
    //padding: 2em;

    //background: red;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .text {
      margin-top: 1.5rem;

      h2 {
        // color: rgba(125, 125, 125, 1);
        // @include set-fontsize(sm);
      }

      li {
        list-style: none;
      }

      a,
      p,
      li {
        white-space: pre-wrap;
        /* CSS3 */
        white-space: -moz-pre-wrap;
        // /* Firefox */
        white-space: -pre-wrap;
        //  Opera <7 
        white-space: -o-pre-wrap;
        // /* Opera 7 */
        word-wrap: break-word;
        /* IE */

      }
    }
  }
}


// xs: 0,
// sm: 576px,
// md: 768px,
// lg: 992px,
// xl: 1200px,
// xxl: 1400px
@include media-breakpoint-up(sm) {
  .__grid__ {
    grid-template-columns: repeat(auto-fit, minmax(180px, 2fr));
    column-gap: 1.5rem;
  }
}

@include media-breakpoint-up(md) {
  .__grid__ {
    grid-template-columns: repeat(auto-fit, minmax(250px, 3fr));
    column-gap: 1.5rem;
  }
}

@include media-breakpoint-up(lg) {
  .__grid__ {

    grid-template-columns: repeat(auto-fit, minmax(310px, 4fr));
    column-gap: 1.5rem;
  }
}