.scrolling-wrapper {
  overflow-x: auto;
}



.scrolling-wrapper::-webkit-scrollbar {
  width: 10px;
}

.scrolling-wrapper::-webkit-scrollbar-track {
  background-color: none;
  border-radius: 10px;
}

.scrolling-wrapper::-webkit-scrollbar-thumb {
  border-radius: 2px;
  border: 4px solid transparent;
  background-clip: content-box;
  background-color: #121212;
}