@import "normalize";
@import "fonts";

@import url("https://use.typekit.net/azu3uvx.css");
@import "../node_modules/bootstrap/scss/bootstrap";

// Then add additional custom code here

// Custom.scss
// Option B: Include parts of Bootstrap

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
//@use ; "../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here

@import "grid";
@import "hs";

html {
    font-size: 22px;
}

// #eba296;
body {
    padding: 0.1rem;
    background: #f0f0f0;
    --color-text: #000002;
    --color-bg: #ffffff;
    --color-link: #000002;
    --color-link-hover: #000;
    --color-menu: #257a92;
    --color-menu2: #f2572c;
    --color-menu3: #EE2F36;
    --color-menu-hover: #c12a2a;
    --color-img-bg: #ACBEA3;
    color: var(--color-text);
    background-color: var(--color-bg);
    font-family: 'Satoshi', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    --title-size-fallback: 7vw;
    --title-size: clamp(4rem, 7vw, 10rem);
    // --cursor-stroke: none;
    // --cursor-fill: #d2220e;
    // --cursor-stroke-width: 1px;
    overflow-x: hidden;
}

.hidden {
    position: absolute;
    overflow: hidden;
    width: 0;
    height: 0;
    pointer-events: none;
}

/* Page Loader */
body {
    opacity: 0.01;

    &.loading::before,
    &.loading::after {
        content: '';
        position: fixed;
        z-index: 1000;
        display: block;
        opacity: 1;
    }

    &.loading::before {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--color-bg);
        opacity: 0.3;
    }

    &.loading::after {
        top: 50%;
        left: 50%;
        width: 60px;
        height: 60px;
        margin: -30px 0 0 -30px;
        border-radius: 50%;
        opacity: 0.4;
        background: var(--color-link);
        animation: pulse 0.7s linear infinite alternate forwards;
    }

    &.make_visible {
        animation: fadeIn 1.7s linear forwards;
    }

    cursor: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='60' height='60' viewBox='0 060 512'%3e%3cg transform='rotate(45 256 256)'%3e%3crect id='r' x='16' y='216' width='480' height='80' rx='14'/%3e%3cuse href='%23r' transform='rotate(90 256 256)'/%3e%3c/g%3e%3c/svg%3e") 16 16,
    // pointer;

    // cursor: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 512 512'%3e%3cg transform='rotate(45 256 256)'%3e%3crect id='r' x='16' y='216' width='480' height='80' rx='14'/%3e%3cuse href='%23r' transform='rotate(90 256 256)'/%3e%3c/g%3e%3c/svg%3e") 16 16,
    // pointer;

    // a {
    //     cursor: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 512 512'%3e%3cg transform='rotate(0 256 256)'%3e%3crect id='r' x='16' y='216' width='480' height='80' rx='14'/%3e%3cuse href='%23r' transform='rotate(90 256 256)'/%3e%3c/g%3e%3c/svg%3e") 16 16,
    //         pointer;
    // }

    // }
}



@keyframes fadeIn {
    to {
        opacity: 1;
        //transform: scale3d(0.5,0.5,1);
    }
}

@keyframes pulse {
    to {
        opacity: 1;
        transform: scale3d(0.5, 0.5, 1);
    }
}






/* Page Loader */
button,
input[type="submit"],
input[type="reset"] {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;

    outline: inherit;
}

p,
a,
li,
h1,
h2,
h3 {
    color: var(--color-text);
    text-rendering: geometricPrecision;
}

h1,
h2,
h3 {
    font-family: 'Satoshi-black' !important;
}

p,
a,
li {
    font-family: "Satoshi-Bold";

}

a,
li {
    text-decoration: none;
    list-style: none;
}

img {
    max-width: 100%;
    font-style: italic;
    vertical-align: middle
}

img[height],
img[width] {
    max-width: none
}

img:not([src]):not([srcset]) {
    visibility: hidden;
}

.page-transition__panel {
    // visibility: hidden;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgb(255, 95, 42);
}

header {
    nav {
        &.c-navbar {
            left: 0;
            margin-top: 15px;
            position: fixed;
            top: 0;
            -webkit-transition: opacity 0.3s, visibility 0.3s;
            -o-transition: opacity 0.3s, visibility 0.3s;
            transition: opacity 0.3s, visibility 0.3s;
            width: 100%;
            z-index: 1000;



            .c-navbar__list {
                -webkit-box-flex: 0;
                -webkit-flex: 0 0 50%;
                -ms-flex: 0 0 50%;
                flex: 0 0 50%;
                max-width: 50%;
                margin-left: 50%;
                margin-top: 3vh;
                z-index: 100;


            }

            .c-navbar__footer {
                font-size: 10px;
                font-size: 0.45rem;
                letter-spacing: -0.02em;
                line-height: 18px;
                bottom: 15px;
                position: absolute;
                right: 15px;
            }

            .c-navbar__links {

                background-color: var(--color-menu3);

                height: 100%;
                left: 0;
                padding-top: 15px;
                position: fixed;
                top: 0;
                -webkit-transform: translateY(-100%);
                -ms-transform: translateY(-100%);
                transform: translateY(-100%);
                -webkit-transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
                -o-transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
                /* transition: all .5s cubic-bezier(.165,.84,.44,1); */
                /* -webkit-transition-delay: .5s; */
                -o-transition-delay: 0.5s;
                /* transition-delay: .5s; */
                width: 100%;


                .c-navbar__social-link__mobile {
                    display: block;
                }

                .c-navbar__contact__mobile {
                    display: block;
                }

                .c-navbar__social-link {
                    display: none;
                }

                .c-navbar__contact {
                    display: none;
                }

                @media (min-width: 768px) {
                    .c-navbar__social-link {
                        display: flex;
                    }

                    .c-navbar__contact {
                        display: flex;
                    }
                }

                .c-navbar__social-link {
                    font-family: 'Satoshi-Bold';
                    font-size: 0.85rem;
                    letter-spacing: -0.02em;
                    line-height: 0.95rem;
                    text-decoration: none;
                    -webkit-transition: opacity 0.3s;
                    -o-transition: opacity 0.3s;
                    transition: opacity 0.3s;
                }

                .c-navbar__contact {

                    position: relative;
                    width: 100%;
                    min-height: 1px;
                    padding-right: 7.5px;
                    padding-left: 7.5px;
                    -webkit-box-flex: 0;
                    -webkit-flex: 0 0 25%;
                    -ms-flex: 0 0 25%;
                    flex: 0 0 25%;
                    max-width: 25%;

                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                    -webkit-flex-direction: column;
                    -ms-flex-direction: column;
                    flex-direction: column;

                    .c-navbar__contact-link {
                        font-family: 'Satoshi-Bold';
                        font-size: 0.85rem;
                        letter-spacing: -0.02em;
                        line-height: 0.95rem;

                    }
                }
            }

            .c-navbar__container {


                position: relative;
                width: 100%;
                min-height: 1px;
                padding-right: 15.5px;
                padding-left: 15.5px;
                -webkit-box-flex: 0;
                -webkit-flex: 0 0 100%;
                -ms-flex: 0 0 100%;
                flex: 0 0 100%;
                max-width: 100%;
                -webkit-box-align: start;
                -webkit-align-items: flex-start;
                -ms-flex-align: start;
                align-items: flex-start;
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: justify;
                -webkit-justify-content: space-between;
                -ms-flex-pack: justify;
                justify-content: space-between;
                z-index: 1000;

                .c-navbar__brand {
                    color: #000002;
                    font-size: 0.85rem;
                    letter-spacing: -0.02em;
                    line-height: 0.95rem;
                    position: relative;
                    display: block;
                    font-family: 'Satoshi-Bold';
                    text-decoration: none;
                    -webkit-transition: opacity 1.3s;
                    -o-transition: opacity 1.3s;
                    transition: opacity 1.3s;

                }

                .c-navbar__menu {
                    position: relative;
                    -webkit-transition: background-color 0.3s, opacity 0.3s, visibility 0.3s;
                    -o-transition: background-color 0.3s, opacity 0.3s, visibility 0.3s;
                    transition: background-color 0.3s, opacity 0.3s, visibility 0.3s;

                    height: 40px;
                    width: 40px;

                    svg {
                        z-index: 0;
                    }
                }
            }

            &.is-active {
                .c-navbar__links {
                    opacity: 1;
                    -webkit-transition-delay: 0.2s;
                    -o-transition-delay: 0.2s;
                    transition-delay: 0.2s;
                    visibility: visible;

                    transform: translateY(00%);

                    .c-navbar__list {
                        margin-left: 0;
                        margin-top: 5vh;

                        .c-navbar__list-item a {
                            font-family: 'Satoshi-Black';
                            color: #000002;
                            font-size: calc(2.525rem + 4.3vh);
                            line-height: 2.8rem;

                        }
                    }
                }
            }
        }
    }
}


//position: fixed;

// nav{
//     //background: #f2f3f3;
//     height: 3rem;
//     top: 0;
//     left: 0;
//     width: 100%;
// }

// .header{
//     display: grid;
//     grid-template-columns: repeat(20, 1fr);
//     grid-gap: 10px;
//     grid-auto-rows: minmax(100px, auto);

//     .title{

//         z-index: 20;
//         grid-column: 4 / 5;
//         grid-row: 2;
//     }
//     .menuitem{

//         &.a{
//             grid-column: 12/ 14;
//             grid-row: 1;
//        }
//        &.b{
//         grid-column: 9/ 12;
//         grid-row: 3;
//    }

//     }

//     .elemnts{

//     display:flex;
//     flex-direction:column;
//     justify-content:center;
//     text-align:center;
//     padding: 0;

// }

// // }



.menu {}

main {
    padding-top: 4vh;
    display: block;
    position: relative;

    .nav-link {
        position: absolute;
        display: none;
        top: 50%;
        color: rgb(35, 129, 153);
        font-size: 10rem;
        z-index: 0;
        //left: 50%;
        // display: none;
        opacity: 0;

        &.active {
            //display: block;
            -webkit-transition: opacity 1s ease-out;
            -moz-transition: opacity 1s ease-out;
            -o-transition: opacity 1s ease-out;
            transition: opacity 3s ease-out;
            opacity: 1;
        }

        &.left {
            left: 30%;
        }

        &.right {
            right: 30;
        }
    }
}

.container {
    .cover {
        content: test;
        //background: #3b8884;
        z-index: 10;
    }
}


.loading_banner {
    background-color: black;
    height: 100%;
    width: 100%;
    left: 0;
    position: fixed;
    top: 0;
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    -o-transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    /* transition: all .5s cubic-bezier(.165,.84,.44,1); */
    /* -webkit-transition-delay: .5s; */
    -o-transition-delay: 0.5s;
    /* transition-delay: .5s; */


    transform: translateY(100%);
    z-index: 100;


    &.is-active {
        opacity: 1;
        -webkit-transition-delay: 0.2s;
        -o-transition-delay: 0.2s;
        transition-delay: 0.2s;
        visibility: visible;
        transform: translateY(00%);

    }
}


.barba-leave-active,
.barba-enter-active {
    transition: opacity 1450ms ease;
}

.barba-leave,
.barba-enter-to {
    opacity: 1;
}

.barba-enter,
.barba-leave-to {
    opacity: 0;
}

#info {

    .info-text {

        font-family: "Satoshi-Medium" !important;
        line-height: 1.2;
        font-size: 20pt;

    }


}

.cs-item-media-container {
    display: block;
    -webkit-transform: scale(.65);
    -ms-transform: scale(.65);
    transform: scale(.65);
    -webkit-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center
}

.cs-item-media-container::before {
    background-color: currentColor;
    border-radius: 100%;
    // content: "";
    height: 6vw;
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: scale(0) translate(-50%, -50%);
    -ms-transform: scale(0) translate(-50%, -50%);
    transform: scale(0) translate(-50%, -50%);
    -webkit-transform-origin: top left;
    -ms-transform-origin: top left;
    transform-origin: top left;
    -webkit-transition: all .35s cubic-bezier(1, 0, 0, 1);
    -o-transition: all .35s cubic-bezier(1, 0, 0, 1);
    transition: all .35s cubic-bezier(1, 0, 0, 1);
    width: 6vw;
    z-index: 100
}

.row.cs-item-media {
    opacity: 0;
}

.row:first-child {
    .cs-item-media {
        opacity: 1 !important;
    }

    // .cs-item-media-container{
    //     background: none!important;
    // }

}



picture {
    .cs-item-media {
        margin: 0 auto;
        width: 100%;
        height: auto;
        //max-width: none;
        opacity: 0;
        // width: calc(100% + 1px);
    }

    img {

        display: block;
        width: 100%;
        height: auto;
        min-width: 20px;
        opacity: 0.3;


        &.loaded {
            opacity: 0;
        }
    }

    img:not([src]) {
        visibility: hidden;
    }

}

#prints {
    .__grid__ {
        .item {
            &.image__open {
                position: fixed;
                display: block;
                height: 100vh;
                width: 100vw;
                left: 0;
                top: 0;
                background: rgb(72, 72, 72);

                picture {
                    position: absolute;
                    top: -50%;
                    left: -50%;
                    max-width: 500px;
                    height: auto;
                }
            }



        }

        .cover {
            display: none;
            opacity: 0;
            position: fixed;
            height: 100vh;
            width: 100vw;
            z-index: 0;
            background: #ffffff;
            top: 0;
            left: 0;

            &.cover__active {
                display: block;
                z-index: 1500;

                picture {
                    opacity: 1;

                    img {
                        margin-top: auto;
                        margin-bottom: auto;
                        object-fit: contain;
                        max-height: 95vh;
                        display: block;
                    }
                }

                .row {
                    height: 100vh;
                }

                .row {
                    z-index: 2000;

                    .left {
                        cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVCAMAAACeyVWkAAAACVBMVEX///8AAAD///9+749PAAAAAXRSTlMAQObYZgAAAEZJREFUeAGt0EEKgDAUA1En9z+0qEENhA+FvlUZQqE9Bnynlmlr2iVkSY66AHrg+MddSUiU6VuxqMhYquzcCvO6PZjhx5oTQ+8Bs3EshqIAAAAASUVORK5CYII=), auto;
                    }

                    .middle {
                        cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAAACVBMVEX///8AAAD///9+749PAAAAAXRSTlMAQObYZgAAAEdJREFUeAF90FEKgDAMwFDT+x9awYCRwjL282CM9joFsGie/swYy6oiENW8RaJ5PBvxvCpqxfwetTOqeyo+Rgx3JjnrCxNa3TwuAY6NyoMhAAAAAElFTkSuQmCC), auto;
                    }

                    .right {
                        cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAaCAMAAABigZc2AAAACVBMVEX///8AAAD///9+749PAAAAAXRSTlMAQObYZgAAAFpJREFUeNp9jUEOwEAIAhn+/+imjbs1bC0XzQioSUAAYZs0mcVY051RbGXfFsyxibTVHXhuhXYlbuRGPbe7kz3g0wf679OuizDBdCaberLE4AsjGliD02eNugA+MQFAPqynHQAAAABJRU5ErkJggg==), auto;
                    }
                }
            }
        }
    }
}

#project {
    .cs-item-content {
        margin-top: 5vh;
        margin-bottom: 5vh;

        li,
        a {
            font-size: 0.65rem;
            letter-spacing: -0.02em;
            line-height: 0.95rem;
            position: relative;
            display: block;
            font-family: "Satoshi-Bold";
            text-decoration: none;
        }
    }

    picture {


        img {

            z-index: 1;
            background: #ACBEA3;
            height: fit-content;
            width: fit-content;

            width: 100%;
            min-height: 100px;
            // background-color:var(--color-img-bg);
            height: auto;


            opacity: 0.5;

            //aspect-ratio: 2/3;

            &.loaded {
                -webkit-transition: opacity 1s ease-out;
                -moz-transition: opacity 1s ease-out;
                -o-transition: opacity 1s ease-out;
                transition: opacity 1s ease-out;
                opacity: 1;

            }


        }
    }
}

#info {

    p,
    a,
    h1,
    h2,
    h3 {
        font-family: 'Satoshi-Medium';
        font-size: 0.75rem;
        //font-style: bold;
    }
}


.__grid__ {

    .item {
        display: block;
        -webkit-transform: scale(0.9);
        -ms-transform: scale(0.9);
        transform: scale(0.9);
        -webkit-transform-origin: center center;
        -ms-transform-origin: center center;
        transform-origin: center center;

        picture {
            margin: 0 auto;
            width: 100%;
            height: auto;
            //max-width: none;

            // width: calc(100% + 1px);



            img:not([src]) {
                visibility: hidden;
            }

            img {

                background: #263f1a;
                display: block;
                width: 100%;
                height: auto;
                min-width: 100px;
                -webkit-transition: opacity 1s ease-out;
                -moz-transition: opacity 1s ease-out;
                -o-transition: opacity 1s ease-out;
                transition: opacity 1s ease-out;
                opacity: 0.5;
                //aspect-ratio: 2/3;

                &.loaded {
                    -webkit-transition: opacity 1s ease-out;
                    -moz-transition: opacity 1s ease-out;
                    -o-transition: opacity 1s ease-out;
                    transition: opacity 1s ease-out;
                    opacity: 1;

                }


            }
        }
    }
}


#prints {
    p {
        font-size: 0.75remd;
    }
}